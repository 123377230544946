import React from "react";
import { graphql } from "gatsby";

import { Markdown } from "@/components/markdown";
import { SEO } from "@/layout/SEO";

const LegalTemplate = ({ data, location }) => {
  return (
    <>
      <Markdown html={data.post.html} />
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...SeoFields
      }
      html
      fields {
        slug
      }
    }
  }
`;

export const Head = ({ location, data, params, pageContext }) => {
  const {
    author,
    description,
    metaDescription,
    robots,
    schemaOrg,
    seoImage,
    title,
    titleTag,
  } = data.post.frontmatter;

  return (
    <SEO
      author={author}
      description={description}
      metaDescription={metaDescription}
      pathname={location.pathname}
      robots={robots}
      schemaOrg={schemaOrg}
      seoImage={seoImage}
      title={title}
      titleTag={titleTag}
    />
  );
};

export default LegalTemplate;
